.sectiononehead span{
  text-transform: uppercase;
  font-weight: 600;
 }
 
 .sectionthree .head{
     display: flex;
     justify-content: space-around;
      /* padding: 60px;  */
      /* width:70%;  */
 }
 .limitedoffer{
   /* background: linear-gradient(rgba(255, 255, 255, 0.062), rgba(255, 255, 255, 0.199)), #594912; */
     /* background-image: url("../Images/card4pic.jpg"); */
      background-repeat: no-repeat; 
     /* background-position: center; */
      background-size:auto; 
     /* width: 100%;  */
     /* height: 40%; */
     /* opacity: 0.8; */
 }
 
 
 .reviewbackground {
   position: relative;
 }
 
 .overlay {
   position: absolute;
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;
   background: url("../Images/happycustomer.jpg");
   background-repeat: no-repeat; 
   background-size: cover;
   opacity: 0.6; 
 }
 
 .content {
   position: relative;
   z-index: 1; 
 }
 
 .blinkword {
   animation: blink 1.2s infinite;
   
 }
 
 @keyframes blink {
   0% {
     opacity: 1;
   }
   50% {
     opacity: 0;
   }
   100% {
     opacity: 1;
   }
 }
 
 