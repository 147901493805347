.navbarsection span{
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
    color: white;
}
.badge {
    background-color: #FF4A56;
    color: white;
    padding: 0px 6px;
    text-align: center;
    border-radius: 10px;
    font-size: 11px;
  }

  .navbarsection span:hover{
    color:#FF4A56;
  }

  .orange-circle{
    height: 2rem;
    width: 2rem;
    background:orange;
    border-radius: 999px;
    position: absolute;
    right:28%;
    top:-10% ;
    z-index: -1;
}