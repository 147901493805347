.star-rating {
    
    display:flex;
    flex-direction: row;
    font-size:1.5em;
    justify-content:space-around;
    padding:0 .2em;
    text-align:center;
    width:5em;
    
  }
  
  .star-rating input {
    display:none;
  }
  

  .star-rating :checked ~ label {
    color:#f90;
  }

  .filled-star{
    color: #f90;
  }
  